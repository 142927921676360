<template>
  <div class="detail-container">
    <div class="detail-main">
      <div class="detail-header van-hairline--bottom">
        <div class="detail-title">{{newsData.newsTitle}}</div>
        <p>发表时间：{{newsData.newsPublishDate}}</p>

      </div>
      <div class="detail-body" v-html="newsData.newsDetail"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['id'],
    data() {
      return {
        newsData: {},
      };
    },

    mounted() {
      // console.log(this.id);
      this.getNewsDetail();
    },
    
    methods: {
      /* toBack() {
        this.$router.go(-1);
      }, */
      async getNewsDetail() {
        let result = await this.$http.get({
            url: `/client/web/news/details/${this.id}`
            
        })
        
        let { data } = result;
        // console.log(rows);
        this.newsData = data;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .detail-container {
    margin-bottom: .48rem;
    // padding: 0 .16rem;
    .detail-main {
      .detail-header {
        height: .96rem;
        padding: .16rem;
        .detail-title {
          font-size: .15rem;
          font-weight: 500;
          line-height: .22rem;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: .04rem;
        }
        > p {
          font-size: .12rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          -webkit-transform-origin-x: 0;
          -webkit-transform: scale(0.92);
          transform: scale(0.92);
          transform-origin: left;
        }
      }
      .detail-body {
        padding: .16rem;
        padding-bottom: 0;
        ::v-deep p {
          font-size: .13rem;
          color: rgba(0, 0, 0, 0.85);
          line-height: .234rem !important;
          img {
            width: 100%;
          }
        }

      }
    }
  }
</style>

