<template>
  <div class="news-container">
    <div class="news-nav">
      <ul class="news-nav-content van-hairline--bottom">
        <router-link active-class="active" tag="li" to="/news/1">机构动态</router-link>
		<router-link active-class="active" tag="li" to="/news/4">公益资讯</router-link>
        <router-link active-class="active" tag="li" to="/news/2">时事资讯</router-link>
        <router-link active-class="active" tag="li" to="/news/3">国家政策</router-link>
        
      </ul>
    </div>
    <div class="news-main">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item, index) in newsList" :key="index" class="news-item"  @click="toDetail(item.id)">
            <img :src="item.newsLogo" alt="">
            <div class="news-content">
              <p>{{item.newsTitle}}</p>
              <p>{{item.newsPublishDate ? item.newsPublishDate.split(' ')[0] : ''}}</p>
            </div>
          </div>

        </van-list>
      </van-pull-refresh>
      
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { List, PullRefresh  } from 'vant';
  Vue.use(PullRefresh);
  Vue.use(List);

  export default {

    props: ['id'],
    data() {
      return {
        newsList: [],
        loading: false,
        finished: false,
        refreshing: false,
        total: 0,
        page_no: 1,
        page_size: 10
      };
    },
    mounted() {
      console.log(this.id);
    },
    
    watch: {
      $route: {
        //从详情页回来不需要重新加载
        handler(to, from){
          console.log(to, from);
          if(from) {
            if(from.name !== 'newsDetail') {
              this.refreshing = true
              this.onRefresh();
            }
          }
          
          
        },
        immediate: true,
      }
    },
    
    methods: {
      //上拉加载数据
      async onLoad() {
        // 下拉刷新重新请求数据
        if (this.refreshing) {
          this.newsList = [];
          this.refreshing = false;
        }
        // 异步更新数据
        await this.getNewsList();
        this.page_no++;

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.newsList.length >= this.total) {
          this.finished = true;
        }
      },

      //请求数据列表
      async getNewsList() {
        // console.log(this.id);
        let result = await this.$http.get({
            url: '/client/web/news/list',
            params: {
              'newsType': this.id,
              'pageNum': this.page_no,
              'pageSize': this.page_size
            }
        })
        
        let { total, rows } = result;
        // console.log(rows);
        this.newsList = [...this.newsList, ...rows];
        this.total = total;
        // console.log(this.newsList);
      },

      //刷新数据
      onRefresh() {
        // 清空列表数据
        console.log('refresh');

        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.page_no = 1;
        this.onLoad();
      },

      //跳转到新闻详情页
      toDetail(id) {
        this.$router.push({ name:'newsDetail', params: { id: id }});
      }
    },
      
  }
</script>

<style lang="scss" scoped>
  .news-container {
    .news-nav {
      position: fixed;
      width: 100%;
      top: .48rem;
      background-color: #FFFFFF;
      z-index: 999;
      .news-nav-content {
        display: flex;
        width: 100%;
        > li {
          height: .44rem;
          flex: 1;
          text-align: center;
          line-height: .44rem;
          font-size: .15rem;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          position: relative;
        }
        .active {
          color: #00ACF6;
          &::after {
            content: '';
            width: .32rem;
            height: .02rem;
            background-color: #00ACF6;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -.16rem;
          }
        }
      }
    }
    .news-main {
      margin: .68rem 0 .24rem 0;
      padding: 0 .16rem;
      overflow: hidden;
      min-height: 1.58rem;
      .news-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: .24rem;
        > img {
          width: 1.2rem;
          height: 0.834rem;
          border-radius: .08rem;
          // background-color: red;
        }
        .news-content {
          width: 2.11rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > p {
            &:nth-child(1) {
              font-size: .15rem;
              font-weight: 500;
              line-height: .21rem;
              color: rgba(0, 0, 0, 0.85);
            }
            &:nth-child(2) {
              font-size: .12rem;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6); 
              -webkit-transform-origin-x: 0;
              -webkit-transform: scale(0.92);
              transform: scale(0.92);
              transform-origin: left;
            }
          }
        }
      }
    }
  }
</style>